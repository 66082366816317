import { Component, Input } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs';
import { cDataService } from 'src/app/services/data.service';
import { cSessionService } from 'src/app/services/session.service';
import { PaymentDetailsService } from 'src/app/services/payment-details.service';
import { environment } from "src/environments/environment";


@Component({
  selector: 'app-navigation-buttons',
  templateUrl: './navigation-buttons.component.html',
  styleUrls: ['./navigation-buttons.component.scss']
})
export class NavigationButtonsComponent {
  @Input() saasEnvButtons;
  docState: any;
  matching: any;
  viewBtn: boolean;
  oUserDetails: any
  sSelectedFolderItem: any;
  activeBrowseDocs: boolean = false;
  sharedButtons: any = {
    documents: false,
    matching: false,
    bulkUpload: false,
  }
  isSchemasRoute: boolean = false;
  environment : any = environment;


  // Non-Saas Buttons
  currentUrl = window.location.href;
  applications: { [key: string]: boolean } = {};
  hasRequested:number | string;



  constructor(
    private oDataService: cDataService,
    private oSessionService: cSessionService,
    private oRoute: Router,
    private paymentDetails : PaymentDetailsService,

  ) {
    this.oRoute.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe((event: NavigationEnd) => {
        this.isSchemasRoute = event.url === '/doc-manager/schemas';
        if (this.isSchemasRoute) {
          Object.assign(this.sharedButtons, { bulkUpload: false, documents: false, matching: false });
        }
      });

  }

  ngOnInit(): void {
    this.oUserDetails = this.oSessionService.SessionService_GetUserDetails();
    if(this.saasEnvButtons) this.getApplicationAccess();
    this.oDataService.headerViewBtn.subscribe((res) => {
      let docStates = JSON.parse(localStorage.getItem("docState"));
      this.sharedButtons = docStates;
      this.viewBtn = res;
    });
    this.oDataService.activeBrowseDocs.subscribe((res) => {
      this.activeBrowseDocs = res;
    });
  }
  onButtonClick(buttonType: string) {
    Object.keys(this.sharedButtons).forEach(key => {
      this.sharedButtons[key] = false;
    });
    this.sharedButtons[buttonType] = true;
    this.setState();
    this.oRoute.navigate(['/doc-manager']).then(() => {
      window.location.reload();
    });
    if (buttonType === 'documents') {
      this.oDataService.switchToDocuments.next(true);
      this.oDataService.activeBrowseDocs.next(true);
    } else {
      this.oDataService.activeBrowseDocs.next(false);
    }
    if (buttonType === 'matching') {
      this.oDataService.globalSearch.next({ folderId: '', searchBoolan: false });
    } else if (buttonType === 'home') {
      localStorage.removeItem('searchQuery');
      this.oDataService.resetSearch.next(true);
      this.oDataService.newConversation.next(true);
    }
    this.oDataService.headerViewBtn.next(true);
  }



  setState() {
    localStorage.setItem("docState", JSON.stringify(this.sharedButtons));
    this.sSelectedFolderItem = this.oSessionService.cSessionService_GetDocumentManagerSelectedItem();
    this.oDataService.globalSearch.next({
      folderId: this.sSelectedFolderItem?.id,
      searchBoolan: this.docState,
    });
  }

  getApplicationAccess()
  {
    const userDetails = this.oSessionService.SessionService_GetUserDetails();
    let body = {
      tenantId : parseInt(userDetails.tenantId),
      userId : userDetails.userId
    }
    this.paymentDetails.getUserApplications(body)
    .subscribe({
      next: (subscription) => {
        console.log(subscription)
        this.applications = subscription.applications;
        this.hasRequested = subscription.hasRequested;
      },
      error: (error) => {
        console.error('Error fetching user applications:', error);
      }
    });
  }
  
  switchToDocuments() {
    this.oDataService.switchToDocuments.next(true);
    this.oDataService.activeBrowseDocs.next(true);
    this.oRoute.navigate(['/doc-manager']);
  }

  navigateToApplicationUrl(applicationUrl,applicationToNavigate){
    const user = encodeURIComponent(JSON.stringify(this.oUserDetails))
    let accessToken = this.oSessionService.SessionService_GetAccessToken()
    const isDocAiApp = applicationToNavigate == 'Data' ? true : false;
    const routeUrl = applicationUrl; // e.g., '/event-details'
    window.location.href = isDocAiApp ? `${routeUrl}?token=${accessToken}&&user=${user}&&isDocAiApp=data` : `${routeUrl}?token=${accessToken}&&user=${user}`;
  }
  
  onSearchButtonClick(ApplicationUrl){
    const user = encodeURIComponent(JSON.stringify(this.oUserDetails))
    let accessToken = this.oSessionService.SessionService_GetAccessToken()
    const UrlToNavigate = `${ApplicationUrl}?token=${accessToken}&&user=${user}&&env=non-saas`
     window.location.assign(UrlToNavigate)
  }

  onDashboardButtonClick() {
    this.oRoute.navigate(['/dashboard']);
  }
}
