import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import { NgbModal, NgbModalRef } from "@ng-bootstrap/ng-bootstrap";
import { cDataService } from "src/app/services/data.service";
interface EnumOption {
  name: string;
  displayName: string;
}

interface BaseProperty {
  name: string;
  displayName: string;
}

interface EnumProperty extends BaseProperty {
  type: 'enum';
  enums: EnumOption[];
}

interface StringProperty extends BaseProperty {
  type: 'string';
}

interface BooleanProperty extends BaseProperty {
  type: 'boolean';
}

type PropertyType = 'enum' | 'string' | 'boolean';
type Property = EnumProperty | StringProperty | BooleanProperty;

interface Activity {
  name: string;
  title: string;
  required: boolean;
  enabled?: boolean;
  description: string;
  properties?: Property[];
  defaultPromptName?: string;
  comment?: string;
  execute?: string;
}
interface PropertyConfig {
  name: string;
  displayName: string;
  type: string;
  value?: any;
  array?: boolean;
  required?: boolean;
  enums?: Array<{ name: string, displayName: string }>;
  uiComponent?: string;
  min?: number;
  max?: number;
  step?: number;
}

interface WorkflowData {
  name?: string;
  title?: string;
  enabledActivitiesCopy?: Activity[];
}
@Component({
  selector: 'app-execute-workflow-new',
  templateUrl: './execute-workflow-new.component.html',
  styleUrls: ['./execute-workflow-new.component.scss']
})
export class ExecuteWorkflowNewComponent {
  @Input() file;
  @Input() schemaProperty: boolean;
  @Input() WorkflowType
  @Input() selectedFolder
  isWorkflow:boolean=false
  searchQAProperties: PropertyConfig[] = [];
  switches = [
    { key: 'processAll', value: false, label: 'Process All' },
  ];
  numberOfConcurrentTasks: number = 1;
  customSearchQAProps: PropertyConfig[] = [
    {
      "name": "useSummaryForContext",
      "displayName": "Use Summary for Context",
      "type": "boolean"
    },
    {
      "name": "numberOfSemanticMatchingResults",
      "displayName": "Number of Semantic Matching Results",
      "type": "integer"
    },
    {
      "name": "numberOfTermSearchResults",
      "displayName": "Number of Term Search Results",
      "type": "integer"
    },
    {
      "name": "resultDisplayPolicy",
      "displayName": "Result Display Policy",
      "type": "enum",
      "value": "page",
      "enums": [
        {
          "name": "page",
          "displayName": "One Result Per Page"
        },
        {
          "name": "chapter",
          "displayName": "One Result Per Chapter"
        }
      ],
      "uiComponent": "radio"
    },
    {
      "name": "modelTemperature",
      "displayName": "Model Temperature",
      "type": "range",
      "value": 0.2,
      "min": 0,
      "max": 2,
      "step": 0.1,
      "uiComponent": "slider"
    },
    {
      "name": "customSearchFields",
      "displayName": "Custom Search Fields",
      "type": "string",
      "array": true,
      "uiComponent": "taglist"
    }
  ];
  noSchemaToShow: boolean = false;
  selectedItems: string[] = [];
  bLoading: boolean = false;
  showWorkflow: boolean;
  bResponseAlert: boolean;
  resMessage: { message: any; responseType: any; color: any };
  private modalRef: NgbModalRef | null = null;
  customTags: string[] = [];
  workflowForm: FormGroup;
  formErrors: { [key: string]: string } = {};
  workflowData: WorkflowData = {};
  @Output() schemaUpdated = new EventEmitter<boolean>();
  showPromptSection: boolean = false;
  selectedActivity: string = "";
  completeWorkFlowList: any;
  constructor(
    private dataService: cDataService,
    private modalService: NgbModal,
    private fb: FormBuilder,

  ) {
    this.workflowForm = this.fb.group({});
  }
  rangeLabels: number[] = [];
  // ****************************************** ngOnInit ***************************************

  ngOnInit(): void {
    this.getWorkFlow();
  }

  // ****************************************** Api's ***************************************

  getWorkFlow() {
    this.bLoading = true;
    console.log(this.WorkflowType,this.schemaProperty,this.file);
    
    this.dataService.cDataService_GetWorkflow(this.selectedFolder.id + '?workflowType=' + this.WorkflowType).subscribe({
      next: (result) => {
        if (result.status == "failed") {
          this.bLoading = false;
          this.noSchemaToShow = true;
        } else {
          if (result?.content && typeof result?.content === 'string') {
            result.content = JSON.parse(result.content);
          }
          this.workflowData = result?.content as WorkflowData;
          this.selectedActivity = this.workflowData?.enabledActivitiesCopy[0]?.name;
          this.completeWorkFlowList = result;
          this.initializeForm();
          this.bLoading = false;
        }
      },
      error: (error) => {
        this.bLoading = false;
        console.error("getWorkFlow : Error ==>", error);
      },
    });
  }

  // ****************************************** Form ***************************************
  initializeForm() {
    const group: any = {};
  
    this.workflowData?.enabledActivitiesCopy?.forEach(activity => {
      group[`${activity.name}_enabled`] = [{
        value: activity.enabled ?? false,
        disabled: false
      },];
      
      // Set default value of execute to 'ifNeeded'
      group[`${activity.name}_execute`] = [{
        value: activity.execute ?? 'ifNeeded',
        disabled: false
      }, activity.required ? [Validators.requiredTrue] : []];
  
      if (activity?.properties && activity.name != 'searchQA' && activity?.properties?.length > 0) {
        activity?.properties?.forEach(prop => {
          let defaultValue: any;
          let validators = [];
  
          switch (prop.type) {
            case 'boolean':
              defaultValue = prop['value'] ?? false;
              if (activity.required) validators.push(Validators.requiredTrue);
              break;
            case 'enum':
              defaultValue = prop['value'] ?? (prop.enums?.[0]?.name || '');
              if (activity.required) validators.push(Validators.required);
              break;
            case 'string':
              defaultValue = prop['value'] ?? '';
              if (activity.required) validators.push(Validators.required);
              break;
            default:
              defaultValue = '';
          }
  
          group[`${activity.name}_${prop.name}`] = [defaultValue, validators];
        });
      }
      
    });
    this.workflowForm = this.fb.group(group);
    this.workflowData?.enabledActivitiesCopy?.forEach(activity => {
      this.workflowForm.get(`${activity.name}_execute`)?.setValue('ifNeeded');
    });
    
  }
  


  onSubmit() {
      const formValue = this.workflowForm.value;
      const processedData = this.processFormData(formValue);
      this.saveWorkFlow(processedData)

  }

  private processFormData(formValue: any) {
    const result: any = {
      activities: []
    };
  
    this.workflowData.enabledActivitiesCopy.forEach(activity => {
      const execute = formValue[`${activity.name}_execute`] || 'ifNeeded'; 
      const activityData: any = {
        name: activity.name,
        execute: execute
      };
      result.activities.push(activityData);
    });
  
    return result;
  }
  
  // ****************************************** saveWorkFlow ***************************************


  saveWorkFlow(processedData) {

    let payload: any = {
      activities: processedData?.activities,
      concurrency: this.numberOfConcurrentTasks,
    }
    this.switches?.forEach(element => {
      payload[element.key] = this.file?.isDirectory ?  element.value : true
    });
    if (this.file.isDirectory) {
      payload['folderId'] = this.file.id;
    }
    else {
      payload['docId'] = this.file.id;
    }
    this.dataService.DataService_ExecuteWorkflowForSelectedFolder(payload).subscribe({
      next: (res) => {
        if (res.failed) {
          this.displayAlertMessage(res.message, "failed", "danger");
        } else {
          this.displayAlertMessage(res.message, "success", "success");
          setTimeout(() => {
            this.modalService.dismissAll();
          }, 2000);
        }
      },
      error: (err) => {
        this.displayAlertMessage(err, "failed", "danger");
      },
      complete: () => { },
    });
  }

  dismissModal() {
    this.modalService.dismissAll();
  }

  onClose() {
    if (this.modalRef) {
      this.modalRef.close();
      this.modalRef = null; // Clear the reference after closing
    }
  }
  selectActivity(activity) {
    this.selectedActivity = activity.name;
  }
  updateSwitchValue(switchKey: string, newValue: boolean) {
    const switchToUpdate = this.switches.find(s => s.key === switchKey);
    if (switchToUpdate) {
      switchToUpdate.value = newValue;
    }

  }
  // ****************************************** validateInput ***************************************

  validateInput(event: KeyboardEvent) {
    const input = event.target as HTMLInputElement;
    // Prevent non-numeric input except for control keys like Backspace
    if (!/^[0-9]$/.test(event.key) && event.key !== 'Backspace') {
      event.preventDefault();
      return;
    }
    // Calculate the new value considering the current input
    const newValue = input.value ? parseInt(input.value + event.key, 10) : parseInt(event.key, 10);

    // Check if the new value is within the allowed range (1-100)
    if (newValue < 1 || newValue > 100) {
      event.preventDefault();
    }
  }

  // ****************************************** Functions ***************************************

  hasProperties(activity: Activity): boolean {
    return !!activity.properties && activity.properties.length > 0;
  }

  isEnumProperty(prop: Property): prop is EnumProperty {
    return prop.type === 'enum';
  }

  isBooleanProperty(prop: Property): prop is BooleanProperty {
    return prop.type === 'boolean';
  }

  isStringProperty(prop: Property): prop is StringProperty {
    return prop.type === 'string';
  }

  getPropertyValue(activityName: string, propertyName: string): any {
    return this.workflowForm.get(`${activityName}_${propertyName}`)?.value;
  }

  isActivityEnabled(activityName: string): boolean {
    return this.workflowForm.get(`${activityName}_enabled`)?.value ?? false;
  }

  getErrorMessage(controlName: string): string {
    return this.formErrors[controlName] || '';
  }

  hasError(controlName: string): boolean {
    return !!this.formErrors[controlName];
  }

  ngDoCheck(): void {
    let panel = document.getElementsByClassName("ng-dropdown-panel-items");
    if (panel.length > 0) {
      let panelWidth = document.getElementById("schema-label").offsetWidth;
      panel[0].id = "panelWidth";
      document.getElementById("panelWidth").style.width = panelWidth + "px";
    }
  }

  // ****************************************** ALERT MESSAGES ***************************************


  displayAlertMessage(
    sIncommingMessage,
    sIncommingResponseType,
    sIncommingColor
  ) {
    this.bResponseAlert = true;
    this.resMessage = {
      message: sIncommingMessage,
      responseType: sIncommingResponseType,
      color: sIncommingColor,
    };
    setTimeout(() => {
      this.bResponseAlert = false;
    }, 3000);
  }
  openEditWorkflowModal(incommingModal){
    this.modalService.dismissAll();
    this.modalService.open(incommingModal, {
      centered: true,
      size: "fullscreen",
      backdrop: "static",
    });
  }
  isEditMode:boolean=false
  toggleMode(): void {
    this.isEditMode = !this.isEditMode;
    // this.modeChange.emit(this.isEditMode);
  }

  setMode(isEdit: boolean): void {
    this.isEditMode = isEdit;
    // this.modeChange.emit(this.isEditMode);
  }
}
