<app-alert-messages *ngIf="bResponseAlert" [resMessage]="resMessage"></app-alert-messages>
<div class="modal-header d-flex justify-content-between align-items-center">
  <div>

    <h4 class="modal-title" id="modal-basic-title">Execute Workflow({{file.name}})</h4>
  </div>
  <div class="d-flex align-items-center">
    <!-- <button type="button" class="btn btn-outline-secondary me-2 btn-sm" (click)="dismissModal()">Cancel</button> -->
    <!-- <button type="submit" class="btn btn-primary me-2 btn-sm" (click)="openEditWorkflowModal(schemaWorkflow)"> <i class="fas fa-pen me-1"></i>Edit Workflow</button> -->
    <button type="submit" class="btn btn-primary me-2 btn-sm" (click)="onSubmit()">
      <i class="fas fa-play me-1"></i> Execute
    </button>
    <button class="toggle-btn" [class.active]="isWorkflow" (click)="openEditWorkflowModal(schemaWorkflow)" ngbTooltip="Edit Workflow" placement="left">
      <i class="fa-solid fa-pencil"></i>
    </button>
    <button type="button" class="btn-close" aria-label="Close" (click)="dismissModal()"></button>
  </div>
</div>



<div *ngIf="!noSchemaToShow && !bLoading" class="modal-body p-0" style="height: calc(100vh - 120px);overflow: auto;">
  <div class="workflow-container">
    <h2 *ngIf="workflowData?.title">{{ workflowData.title }}</h2>

    <div *ngIf="file?.isDirectory" class="top-card ">
      <div class="d-flex  align-items-center">
       
        <div class="me-4 mt-1" *ngFor="let switchItem of switches">
          <div class="form-check grid-view-checkbox d-flex align-items-center">
            <input type="checkbox" class="form-check-input" [id]="switchItem.key" [(ngModel)]="switchItem.value"
              (change)="updateSwitchValue(switchItem.key, switchItem.value)">
            <label [for]="switchItem.key" class="form-check-label ml-2">
              <strong>{{ switchItem.label }}</strong>
            </label>
          </div>
        </div>


        <div class="ms-5">
          <div class="form-group">
            <label class="form-label font-12"><strong>Number of Concurrent Tasks</strong></label>
            <input class="form-control" type="number" value="0" [(ngModel)]="numberOfConcurrentTasks" max="100"
              placeholder="Enter number" (keypress)="validateInput($event)">
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="workflow-container">
    <form [formGroup]="workflowForm">
      <div class="activities-grid">
        <div *ngFor="let activity of workflowData?.enabledActivitiesCopy" class="activity-card"
          [ngClass]="activity.name == selectedActivity ? 'selected-activity' : ''" (click)="selectActivity(activity)">
          <div class="card-header">
            <h3>{{ activity.title }}</h3>

          </div>

          <div class="card-content">
            <p class="description">{{ activity.description }}</p>

            <small *ngIf="hasError(activity.name + '_enabled')" class="error-message">
              {{ getErrorMessage(activity.name + '_enabled') }}
            </small>
            <div class="badges">
              <span *ngIf="activity.required" class="required-badge">Required</span>
            </div>
            <!-- EXECUTION SWITCHES -->
            <div class="schema-radio d-flex flex-column">
              <label class="custom-radio">
                <input type="radio" [name]="activity.name + '_execute'" [value]="'ifNeeded'"
                  [formControlName]="activity.name + '_execute'">
                <span class="p-0 font-13">Execute (If Needed)</span>
              </label>
              <label class="custom-radio">
                <input type="radio" [name]="activity.name + '_execute'" [value]="'force'"
                  [formControlName]="activity.name + '_execute'">
                <span class="p-0 font-13">Force Execute</span>
              </label>
              <label class="custom-radio" *ngIf="!activity?.required">
                <input type="radio" [name]="activity.name + '_execute'" [value]="'skip'"
                  [formControlName]="activity.name + '_execute'">
                <span class="p-0 font-13">Skip Execute</span>
              </label>
            </div>

            <div class="properties-section"
              *ngIf="hasProperties(activity) && isActivityEnabled(activity.name) && activity.name != 'searchQA'">
              <div *ngFor="let prop of activity.properties" class="property-item position-relative">
                <label [for]="activity.name + '_' + prop.name" [class.marginLeft]="isBooleanProperty(prop)"
                  class="disable-label">
                  {{ prop.displayName }}
                  <!-- <span *ngIf="activity.required" class="required-indicator">*</span> -->
                </label>

                <select *ngIf="isEnumProperty(prop)" [id]="activity.name + '_' + prop.name"
                  [formControlName]="activity.name + '_' + prop.name" class="form-control disable font-13"
                  [class.error]="hasError(activity.name + '_' + prop.name)">
                  <option value="">Select {{ prop.displayName }}</option>
                  <option *ngFor="let option of prop.enums" [value]="option.name">
                    {{ option.displayName }}
                  </option>
                </select>

                <div *ngIf="isBooleanProperty(prop)" class="checkbox-wrapper position-absolute" style="top:-1px">
                  <input type="checkbox" [id]="activity.name + '_' + prop.name"
                    [formControlName]="activity.name + '_' + prop.name" class="form-checkbox disable"
                    [class.error]="hasError(activity.name + '_' + prop.name)">
                </div>

                <input *ngIf="isStringProperty(prop)" type="text" [id]="activity.name + '_' + prop.name"
                  [formControlName]="activity.name + '_' + prop.name" class="form-control disable"
                  [class.error]="hasError(activity.name + '_' + prop.name)" [placeholder]="'Enter ' + prop.displayName">

                <!-- <div *ngIf="hasError(activity.name + '_' + prop.name)" class="error-message">
                  {{ getErrorMessage(activity.name + '_' + prop.name) }}
                </div> -->
              </div>
            </div>


          </div>

        </div>

      </div>
    </form>
  </div>
</div>
<div *ngIf="bLoading" class="d-flex justify-content-center p-4  align-items-center loader-position ">
  <div class="spinner-border" role="status">
    <span class="visually-hidden">Loading...</span>
  </div>
</div>
<div *ngIf="noSchemaToShow && !bLoading" class="d-flex align-items-center justify-content-center p-4">
  <small class="heading">No WorkFlow Found!</small>
</div>

<!-- <div *ngIf="!noSchemaToShow && !bLoading" class="modal-footer">
  <div class="row styling-footer">
    <div class="col-6">
    </div>
    <div class="p-0 col-6 text-end">
      <button type="button" class="btn btn-outline-secondary" (click)="dismissModal()">Cancel</button>
      <button type="submit" class="btn btn-primary ml-2" (click)="onSubmit()">Execute Workflow</button>
    </div>
  </div>
</div> -->

<ng-template #schemaWorkflow let-modal>
  <app-schema-workflow [file]="file" [schemaProperty]="false" [WorkflowType]="WorkflowType"  [selectedFolder]="selectedFolder"></app-schema-workflow>
</ng-template>