<app-alert-messages *ngIf="bResponseAlert" [resMessage]="resMessage"></app-alert-messages>
<div class="modal-header d-flex justify-content-between align-items-center">
  <div>
    <h4 class="modal-title mb-0">{{file?.name}}</h4>
  </div>
  <div class="d-flex align-items-center">
    <!-- Moved Save and Reset buttons to the top -->
    <!-- <button type="button" class="btn btn-primary me-2 btn-sm" (click)="executeWorkflowModal(executeWorkflow)">
      <i class="fas fa-play me-1"></i> Execute
    </button>
     -->
    <button type="submit" class="btn btn-primary me-2 btn-sm" (click)="onSubmit(1)">
      <i class="fas fa-save me-1"></i> Save
    </button>
    <button type="button" class="btn btn-outline-secondary me-2 btn-sm" (click)="openResetWorkFlowModal(resetWorkflowflowModal)">
      <i class="fas fa-undo-alt me-1"></i> Reset
    </button>
    <button class="toggle-btn"  *ngIf="!file?.isHeader" [class.active]="isWorkflow"   (click)="executeWorkflowModal(executeWorkflow)" ngbTooltip="Execute Workflow" placement="left">
      <i class="fa-solid fa-folder-gear"></i>
    </button>
    <button type="button" class="btn-close" aria-label="Close" (click)="confirmCloseIfChanged(confirmationModal)"></button>
  </div>
</div>
<div *ngIf="!noSchemaToShow && !bLoading" class="modal-body p-0" style="height: calc(100vh - 120px);overflow: auto;">
    <div class="workflow-container">
        <h2>{{ workflowData.title }}</h2>
        
        <form [formGroup]="workflowForm">
        <div class="activities-grid">
            <div *ngFor="let activity of workflowData.activities" class="activity-card" [ngClass]="activity.name == selectedActivity ? 'selected-activity' : ''" (click)="selectActivity(activity)">
            <div class="card-header">
                <h3>{{ activity.title }}</h3>
                <div class="toggle-container">
                <label class="switch" *ngIf="!activity?.required">
                    <input type="checkbox" [formControlName]="activity.name + '_enabled'" >
                    <span class="slider round"></span>
                </label>
                </div>
            </div>
    
            <div class="card-content">
                <p class="description mb-4">{{ activity.description }}</p>
                <small *ngIf="hasError(activity.name + '_enabled')" class="error-message">
                {{ getErrorMessage(activity.name + '_enabled') }}
                </small>
                <!-- <div class="badges">
                <span *ngIf="activity.required" class="required-badge">Required</span>
                </div> -->
    
             
                <!-- <div class="properties-section" 
                *ngIf="hasProperties(activity) && isActivityEnabled(activity.name) && activity.name !== 'searchQA'">
              
                <div *ngFor="let prop of activity.properties; let firstIndex = first" class="property-item position-relative">
                  <div class="d-flex align-items-center">
                    <span class="property-value">{{ getDisplayValue(activity, prop) }}</span>
                    <i   *ngIf="firstIndex" class="pencil-icon cursor-pointer ms-2" (click)="openEditDialog(activity, prop,editModal)">✏️</i>
                  </div>
                </div>
                
            </div> -->
            <div class="properties-section" style="margin-bottom: 20px;"
              *ngIf="hasProperties(activity) && isActivityEnabled(activity.name) && activity.name != 'searchQA'">
                  <div *ngFor="let prop of activity.properties" class="property-item-show">
                    <!-- Display for enum properties -->
                      <p class="mb-0" *ngIf="isEnumProperty(prop)">
                        <strong>{{ getEnumDisplayName(activity.name,prop) }}</strong>
                      </p>
                    <!-- Display for boolean properties -->
                      <p class=" d-flex align-items-center justify-content-between mb-0" *ngIf="isBooleanProperty(prop)">
                        <strong>{{ prop.displayName }}:</strong>
                        <span class="status-box" [class.active]="getFormValue(activity.name, prop)">
                          {{ getFormValue(activity.name, prop) ? '✓' : '✗' }}
                        </span>
                      </p>
                    <!-- Display for string properties -->
                      <p class="mb-0" *ngIf="isStringProperty(prop)">
                        {{ prop.displayName }}: <strong>{{ getFormValue(activity.name,prop) || 'N/A' }}</strong>
                      </p>
                  </div>
               </div>
            </div>
            <!-- Edit Properties  -->
            <div   [class.marginEdit]="!hasPrompts(activity)"  *ngIf="hasProperties(activity) && isActivityEnabled(activity.name) && activity.name != 'searchQA'" class="position-absolute"  style="bottom: 5px; right: 30px;">
              <i class="fa fa-pen cursor-pointer font-14" (click)="openEditDialog(activity,editModal)"></i>
            </div>
             <!-- Updated prompt section -->
             <div *ngIf="hasPrompts(activity)" class="position-absolute" style="bottom: 5px; right: 15px;">
              <div ngbDropdown class="d-inline-block">
                <div  
                        id="promptDropdown" 
                        ngbDropdownToggle>
                    
                        <i class="fa-solid fa-ellipsis-vertical ml-2"></i> 
                </div>
                <div ngbDropdownMenu aria-labelledby="promptDropdown">
                  <button *ngFor="let prompt of activity.prompts" 
                          ngbDropdownItem 
                          (click)="selectPrompt(promptModal, prompt, $event)">
                    {{ prompt.displayName }}
                  </button>
                </div>
              </div>
            </div>

            <!-- Fallback for activities with only defaultPromptName -->
            <div *ngIf="!hasPrompts(activity) && activity?.defaultPromptName" 
                class="position-absolute" style="bottom: 8px; right: 15px;">
              <button class="btn btn-outline-secondary" (click)="openPromptModal(promptModal, activity)">
                <i class="fas fa-pen-to-square mr-2"></i> Edit Prompt
              </button>
            </div>
          </div>
          
        </div>
        <div *ngIf="this.workflowForm.get('searchQA_enabled')?.value && !bLoading" class="card shadow-sm p-4 prompt-card">
          <div class="d-flex justify-content-start align-items-center">

          </div>
          <div class="card-body">
            <form [formGroup]="searchQAPropertiesForm" (ngSubmit)="onSubmit()">
              <div class="row mb-4">
                <ng-container *ngFor="let property of searchQAProperties">
                  
                  <!-- Boolean Input -->
                  <div *ngIf="property.type === 'boolean'" class="col-md-12">
                    <div class="form-check mb-3">
                      <input 
                        type="checkbox" 
                        [id]="property.name"
                        class="form-check-input"
                        [formControlName]="property.name"
                        style="margin-top: 6px;"
                      />
                      <label [for]="property.name" class="form-check-label">
                        {{ property.displayName }}
                        <span *ngIf="property.required" class="text-danger">*</span>
                      </label>
                      <small *ngIf="hasErrorofSearchQNA(property.name, 'required')" class="text-danger mt-1">
                        {{ getErrorMessageofSearchQNA(property) }}
                      </small>
                    </div>
                  </div>
        
                  <!-- Number Input -->
                  <div *ngIf="property.type === 'integer' || (property.type === 'string' && !property.array && property.uiComponent != 'taglist')" class="col-md-4">
                    <div class="mb-3">
                      <label [for]="property.name" class="form-label">
                        {{ property.displayName }}
                        <span *ngIf="property.required" class="text-danger">*</span>
                      </label>
                      <input 
                        [type]="property.type === 'integer' ? 'number' : 'text'" 
                        [id]="property.name"
                        class="form-control"
                        [formControlName]="property.name"
                        min="0"
                        [ngClass]="{'is-invalid': hasErrorofSearchQNA(property.name, 'required') || hasErrorofSearchQNA(property.name, 'min')}"
                      />
                      <small *ngIf="hasErrorofSearchQNA(property.name, 'required') || hasErrorofSearchQNA(property.name, 'min')" class="invalid-feedback">
                        {{ getErrorMessageofSearchQNA(property) }}
                      </small>
                    </div>
                  </div>
        
                  <!-- Radio Input -->
                  <div *ngIf="property.type === 'enum' && property.uiComponent === 'radio'" class="col-md-12">
                    <div class="mb-3">
                      <label class="form-label">
                        {{ property.displayName }}
                        <span *ngIf="property.required" class="text-danger">*</span>
                      </label>
                      <div class="row">
                        <div *ngFor="let option of property.enums" class="col-md-4">
                          <label [for]="option.name" class="custom-radio">
                            <input 
                              type="radio"
                              [id]="option.name"
                              [formControlName]="property.name"
                              [value]="option.name"
                              class=""
                            />
                            <span class="">{{ option.displayName }}</span>
                          </label>
                        </div>
                      </div>
                      <small *ngIf="hasErrorofSearchQNA(property.name, 'required')" class="text-danger mt-1">
                        {{ getErrorMessageofSearchQNA(property) }}
                      </small>
                    </div>
                  </div>
        
                  <!-- Range Slider -->
                  <div *ngIf="property.type === 'range' && property.uiComponent === 'slider'" class="col-md-10">
                    <div class="">
                      <label [for]="property.name" class="form-label mb-2">
                        {{ property.displayName }}
                        <span *ngIf="property.required" class="text-danger">*</span>
                      </label>
                      <div class="position-relative my-2">
                        <div class="d-flex justify-content-between position-absolute w-100" style="top: -20px;">
                          <span *ngFor="let value of rangeLabels" class="text-center" style="width: 10%; font-size: 0.8rem;">
                            {{ value }}
                          </span>
                        </div>
                        <div class="d-flex align-items-center">
                          <input 
                            type="range"
                            [id]="property.name"
                            class="form-range"
                            [formControlName]="property.name"
                            [min]="property.min"
                            [max]="property.max"
                            [step]="property.step"
                            (input)="onSliderChange($event)"
                          />
                        </div>
                      </div>
                      <span class="fw-bold mt-2">{{ searchQAPropertiesForm.get(property.name)?.value }}</span>
                      <small *ngIf="hasErrorofSearchQNA(property.name, 'required')" class="text-danger mt-1">
                        {{ getErrorMessageofSearchQNA(property) }}
                      </small>
                    </div>
                  </div>
        
                  <!-- Tag List -->
                  <div *ngIf="property.type === 'string' && property.array && property.uiComponent === 'taglist'" class="col-md-12 mt-3">
                    <label [for]="property.name" class="form-label">
                      {{ property.displayName }}
                      <span *ngIf="property.required" class="text-danger">*</span>
                    </label>
                    <div class="tags-input-container">
                      <span *ngFor="let tag of customTags" class="badge bg-primary me-2">
                        <span style="max-width: 160px; white-space: nowrap; overflow: hidden; text-overflow: ellipsis;">
                          {{ tag }}
                        </span>
                        <button 
                          type="button" 
                          class="btn-close btn-close-white btn-sm"
                          aria-label="Remove"
                          (click)="removeTag(tag)">
                        </button>
                      </span>
                      <input 
                        type="text"
                        [id]="property.name"
                        class="form-control"
                        placeholder="Enter a tag and press Enter"
                        (keyup.enter)="addTag($event)"
                        [ngClass]="{'is-invalid': hasErrorofSearchQNA(property.name, 'required') || hasErrorofSearchQNA(property.name, 'pattern')}"
                      />
                      <small *ngIf="hasErrorofSearchQNA(property.name, 'required') || hasErrorofSearchQNA(property.name, 'pattern')" class="invalid-feedback">
                        {{ getErrorMessageofSearchQNA(property) }}
                      </small>
                    </div>
                  </div>
        
                </ng-container>
              </div>
            </form>
          </div>
        </div>
        </form>
    </div>
</div>
<div *ngIf="bLoading" class="d-flex justify-content-center p-4  align-items-center loader-position ">
  <div class="spinner-border" role="status">
    <span class="visually-hidden">Loading...</span>
  </div>
</div>
<div *ngIf="noSchemaToShow && !bLoading" class="d-flex align-items-center justify-content-center p-4">
  <small class="heading">No WorkFlow Found!</small>
</div>

<!-- <div *ngIf="!noSchemaToShow && !bLoading" class="modal-footer">
    <div class="row styling-footer">
      <div class="col-6">
      </div>
      <div class="p-0 col-6 text-end">
        <button type="button" class="btn btn-outline-secondary" (click)="dismissModal()">Cancel</button>
        <button type="button" class="btn btn-primary ml-2" (click)="openResetWorkFlowModal(resetWorkflowflowModal)" >Reset Workflow</button>
        <button type="submit" class="btn btn-primary ml-2" (click)="onSubmit()">Save Workflow</button>
      </div>
    </div>
</div> -->

<ng-template #promptModal>
<app-edit-prompts [file]="file"  [completeWorkFlowList]="completeWorkFlowList"   (closeModal)="onClose()"></app-edit-prompts>
</ng-template>

<ng-template #resetWorkflowflowModal>
    <div class="modal-header">
      <h4 class="modal-title">Reset Workflow</h4>
      <button type="button" class="btn-close" aria-label="Close" (click)="dismissResetModal()"></button>
    </div>
    <div class="modal-body">
      <p class="mb-3">This will reset workflow from the template. If template is revised with new activities they will be shown. Similar changes will apply for deleted and modified activities.</p>
      <p class="mb-0 mb-3" *ngIf="isResetWorkflow">Prompts will be initialized from the templates. Your old prompts will be versioned. A new prompt will be created and it will become active.</p>
      <div class=" d-flex">
        <input type="checkbox" class="form-check-input cursor-pointer" id="resetPrompts" [(ngModel)]="isResetWorkflow">
        <label for="resetPrompts" class="form-check-label cursor-pointer ml-2">Reset Prompts</label>
      </div>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-outline-secondary" (click)="dismissResetModal()">Cancel</button>
      <button type="button" class="btn btn-primary ml-2" (click)="resetWorkflow()" >Save</button>
    </div>
</ng-template>

<ng-template #editModal let-modal>
  <div class="modal-header">
    <h4 class="modal-title">Edit Property</h4> 
  </div>
  <div class="modal-body properties-section">
      <form [formGroup]="temporaryPropertiesForm">
          <div *ngFor="let prop of selectedEditActivities.properties" class="property-item-modal position-relative">
              <div class="d-flex">
                  <label *ngIf="prop.type != 'enum'" 
                      [for]="prop.name" 
                      [class.marginLeft]="isBooleanProperty(prop)" 
                      class="d-inline-block cursor-pointer">
                      {{ prop.displayName }}
                  </label>
              </div>

            <!-- Enum Select Field -->
              <ng-select *ngIf="isEnumProperty(prop)"
                        [items]="prop.enums"
                        bindLabel="displayName"
                        bindValue="name"
                        placeholder="Select {{prop.name}}"
                        [id]="prop.name"
                        [formControlName]="prop.name" [clearable]="false" [searchable]="false">
              </ng-select>
              <!-- Boolean Checkbox -->
              <div *ngIf="isBooleanProperty(prop)" class="checkbox-wrapper position-absolute" style="top:-1px">
                  <input type="checkbox"
                        [id]="prop.name"
                        [formControlName]="prop.name"
                        class="form-checkbox"
                        [class.error]="hasError(prop.name)">
              </div>

              <!-- String Input Field -->
              <input *ngIf="isStringProperty(prop)" 
                    type="text"
                    [id]="prop.name"
                    [formControlName]="prop.name"
                    class="form-control"
                    [class.error]="hasError(prop.name)"
                    [placeholder]="'Enter ' + prop.displayName" >

              <!-- Error Message -->
              <div *ngIf="hasError(prop.name)" class="error-message">
                  {{ getErrorMessage(prop.name) }}
              </div>
          </div>
      </form> 
  </div>
  <div class="modal-footer">
    <button class="btn btn-outline-secondary" (click)="onClose()">Cancel</button>
    <button class="btn btn-primary ml-2" (click)="closeModal()">Done</button>
  </div>
</ng-template>



<ng-template #executeWorkflow let-modal>
  <app-execute-workflow-new [file]="file"   [schemaProperty]="false" [selectedFolder]="selectedFolder" [WorkflowType]="WorkflowType"></app-execute-workflow-new>
</ng-template>

<ng-template #confirmationModal>
  <div class="modal-header">
    <h4 class="modal-title">Unsaved Changes</h4>
  </div>
  <div class="modal-body">
    <p class="mb-3">You have made changes to the workflow. If you continue, your changes will be lost. Do you wish to continue?</p>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-secondary" (click)="dismissConfirmModal()">Discard</button>
    <button type="button" class="btn btn-primary ml-2" (click)="onSubmit(2)" >Save</button>
  </div>
</ng-template>