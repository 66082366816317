export const environment = {
  production: window["env"]["production"] || false,
  APP_NAME: window["env"]["APP_NAME"] || "SaasTrilloApps",
  Title: window["env"]["Title"] || "Saas Trillo Apps",
  BaseURL: window["env"]["BaseURL"] || "https://api.eng-dev-2-402218.trilloapps.com",
  AccessToken: window["env"]["AccessToken"] || "lsSaasTrilloAppsAccessToken",
  ImagesURL: window["env"]["ImagesURL"] || "",
  Theme: window["env"]["Theme"] || "trillo",
  DocAiApplicationUser:window["env"]["DocAiApplicationUser"] || 'false',
  OrgName: window["env"]["OrgName"] || "cloud",
  Branch : window["env"]["Branch"] || "development/2.0",
  ChatApplication: window["env"]["ChatApplication"] || "http://localhost:4300/search-qna",
  DataApplication: window["env"]["DataApplication"] || "http://localhost:4300/data-review",
  BillingApplication: window["env"]["BillingApplication"] || "http://localhost:4200",
  DocAIApplication: window["env"]["DocAIApplication"] || "http://localhost:4300",
  AdminApplication: window["env"]["AdminApplication"] || "http://localhost:4400/doc-manager",
};
